<template>
  <div class="app-container">
    <div class="wrapper" ref="imageDom">
      <img :src="dataList.cover_img" class="kv"/>
      <div class="foot" v-if="dataList.has_bottom === 1">
        <div class="main">
          <div class="line">{{ dataList.content || '什么都没说' }}</div>
          <div class="block-cut"></div>
          <div class="txt">长按识别二维码收下名片</div>
        </div>
        <img :src="dataList.card_qrcode" class="qrcode" />
      </div>
    </div>

    <!-- <div class="btn">
      <van-button type="info" block @click="saveKv">保存图片</van-button>
    </div>
    <ul class="alert">
      <li>1. 通过微信右上角分享</li>
      <li>2. 点保存图片后，长按图片保存到手机</li>
    </ul> -->
  </div>
</template>
<script>
import html2canvas from "html2canvas"
import { ImagePreview } from 'vant'
import wx from 'weixin-js-sdk'
import qs from 'qs'
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      dataList: {},
      imgUrl: ''
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getDataList()
    }

  },
  methods: {
    setWxShare () {
      let _this = this
      let jsonData = {
        id: this.$route.query.id,
        from_id: Number(Cookies.get('client_id'))
      }
      let shareUrl = location.origin + `/#${this.$route.path}?` + qs.stringify(jsonData)
      console.log(shareUrl)

      wx.ready(function () {
        //分享朋友圈
        wx.updateTimelineShareData({
          title: _this.dataList.realname + '的名片',
          link: shareUrl,
          imgUrl: 'https://szzh-hk.oss-cn-hangzhou.aliyuncs.com/default/logo/2021/0326/ab932202103261131019225.png',
          success: function () {
            // 设置成功
            // _this.$axios.post('/wxc/article/forward', {
            //   article_id: _this.dataList.article_id
            // })
          }
        })

        //转发好友
        wx.updateAppMessageShareData({
          title: _this.dataList.realname + '的名片', // 分享标题
          desc: '', // 分享描述
          link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'https://szzh-hk.oss-cn-hangzhou.aliyuncs.com/default/logo/2021/0326/ab932202103261131019225.png', // 分享图标
          success: function () {
            // 设置成功
            // _this.$axios.post('/wxc/article/forward', {
            //   article_id: _this.dataList.article_id
            // })
          }
        })

      })

    },
    getDataList () {
      this.$axios.post('/wxc/cardfeed/detail', {
        sys_id: Number(this.$route.query.id)
      }).then(res => {
        if (res.code === 200) {
          this.dataList = res.data
          this.setWxShare()
        }
      })
    },
    saveKv () {
      html2canvas(this.$refs.imageDom, {
        allowTaint: true,
        useCORS: true
      }).then(canvas => {
        console.log(canvas)
        // 转成图片，生成图片地址
        this.imgUrl = canvas.toDataURL("image/png")
        ImagePreview([canvas.toDataURL("image/png")]);
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  background-color: #fff;
}
.wrapper {
  width: 100%;
}
.kv {
  display: block;
}
.foot {
  display: flex;
  padding: 10px;
  align-items: center;
  .main {
    flex: 1;
    .block-cut {
      width: 25px;
      height: 5px;
      background-color: rgba(7, 193, 96, 1);
      margin: 10px 0;
    }
    .txt {
      color: rgba($color: #000000, $alpha: 0.5);
    }
  }
  .qrcode {
    width: 80px;
  }
}

.btn {
  padding: 20px;
}
.alert{
  font-size: 12px;
  padding: 0 20px;
}
</style>